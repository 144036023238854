import { queryRun } from "@fscrypto/domain";
import fileSaver from "file-saver";

const exportToCsv = (columnLabels: string[], results: queryRun.CsvRowsSchema, fileName?: string) => {
  let csvContent = "";
  let header = columnLabels.join(",");
  csvContent += header + "\r\n";
  const downloadFileName = fileName ? `download-${fileName}` : "download";

  results?.forEach((rowArray: unknown[]) => {
    const rowFiltered = Object.values(rowArray).map((data) => {
      if (typeof data === "object") {
        const s = '"' + JSON.stringify(data).replaceAll('"', '""') + '"';
        return s;
      } else {
        return data;
      }
    });
    const row = rowFiltered.join(",");
    csvContent += row + "\r\n";
  });

  // forces UTF-8
  const bom = "\uFEFF";
  csvContent += bom;

  const blob = new Blob([csvContent], { type: "text/plain;charset=utf-8" });
  fileSaver.saveAs(blob, `${downloadFileName}.csv`, { autoBom: true });
};

export default exportToCsv;
