import { QueryRunResult } from "@fscrypto/domain/query-run";
import { Button, Text, Tooltip } from "@fscrypto/ui";
import clsx from "clsx";
import { ChevronRightIcon, Download, Loader2, RotateCcwSquare, Sheet, X } from "lucide-react";
import { DatabaseIcon } from "~/features/app-shell/database-explorer/database-explorer-icon";
import { tracking } from "~/utils/tracking";
import { useQuery } from "../../state/query";
import { useSelectAndRun } from "../../state/query-ephemeral-runs";
import { TablePreview, useTablePreviews } from "../../state/query-table-preview";
import { TableId, useTableState } from "../../state/query-table-state";
import { QueryResultsContainerStore, useQueryResultsStore } from "./query-results";
import { SelectionRunTab } from "./selection-run-tab";
import exportToCsv from "~/utils/csv-exporter";
import { useCurrentUser } from "~/features/current-user";
import MarketingAuthModal from "~/features/app-shell/marketing/marketing-auth-modal";
import { useState } from "react";
import { formatDistanceToNow } from "date-fns";

export const ResultsPanelHeader = ({ queryId, run }: { queryId: string; run?: QueryRunResult }) => {
  const { results } = useSelectAndRun(queryId);
  const { activeResults } = useQueryResultsStore();
  const query = useQuery(queryId);
  const latestRun = query?.latestRun;

  const refreshRateHours = query?.query.ttlMinutes ? Math.round((query.query.ttlMinutes / 60) * 10) / 10 : null;
  const lastRunText = latestRun?.endedAt
    ? `Last run: ${formatDistanceToNow(new Date(latestRun.endedAt), { addSuffix: true })}`
    : null;

  return (
    <div className="scrollbar-hide dark:bg-background/90 flex w-full items-center justify-between gap-x-2 bg-[#f8f8f8] p-2">
      <div className="flex flex-1 items-center gap-x-2">
        <QueryRunTab queryId={queryId} />
        <SelectionRunTab queryId={queryId} />
        <PreviewTableTabs />
      </div>
      <div className="flex items-center gap-x-3">
        <div className="flex flex-col items-end gap-y-1">
          {lastRunText && (
            <Text size="xs" className="text-muted-foreground">
              {lastRunText}
            </Text>
          )}
          {refreshRateHours && (
            <Text size="xs" className="text-muted-foreground">
              Auto-refreshes every {refreshRateHours} {refreshRateHours === 1 ? "hour" : "hours"}
            </Text>
          )}
        </div>
        <DownloadButton queryId={queryId} run={activeResults === "selectAndRun" ? results?.result : run} />
        <TransposeButton queryId={queryId} run={activeResults === "selectAndRun" ? results?.result : run} />
      </div>
    </div>
  );
};

const QueryRunTab = ({ queryId }: { queryId: string }) => {
  const { setActiveResults, activeResults } = useQueryResultsStore();
  const query = useQuery(queryId);
  return (
    <Button
      size="sm"
      variant={activeResults === "query" ? "outline" : "secondary"}
      data-testid="panel-header-results"
      onClick={() => {
        setActiveResults("query");
        query?.resizeIfClosed();
      }}
      className={clsx({
        "bg-background": activeResults === "query",
      })}
    >
      <Sheet
        aria-label="add-button"
        className={clsx("size-4 mr-2 ", {
          "text-primary/80": activeResults === "query",
          "text-muted-foreground": activeResults !== "query",
        })}
      />
      <Text>Results</Text>
    </Button>
  );
};

const PreviewTableTabs = () => {
  const tablePreviews = useTablePreviews();
  const { setActiveResults, activeResults } = useQueryResultsStore();

  const handleRemove = (table: string) => {
    tablePreviews.removeTablePreview(table);
    setActiveResults("query");
  };
  return (
    <div className="ml-3 flex items-center">
      {tablePreviews.previews.map((preview, i) => {
        return (
          <PreviewTableTab
            preview={preview}
            key={i}
            isActive={activeResults === preview.table}
            onSetActive={() => setActiveResults(preview.table)}
            onRemove={handleRemove}
          />
        );
      })}
    </div>
  );
};

interface TablePreviewTabProps {
  preview: TablePreview;
  isActive: boolean;
  onSetActive?: (str: string) => void;
  onRemove?: (str: string) => void;
}
export const PreviewTableTab = ({ preview, isActive, onSetActive, onRemove }: TablePreviewTabProps) => {
  const { table, schema, database } = convertTablePath(preview.table);
  return (
    <Button
      size="sm"
      variant={isActive ? "outline" : "secondary"}
      className={clsx({ "bg-background": isActive })}
      onClick={() => onSetActive?.(preview.table)}
    >
      <DatabaseIcon name={database!} size="sm" />
      <Text size="xs" color="content">
        {schema}
      </Text>
      <ChevronRightIcon className="size-4 text-contrast" />
      <Text size="xs" color="content">
        {table}
      </Text>
      {preview.data ? (
        <X
          className="text-content/40 size-4 mx-2 cursor-pointer"
          style={{ opacity: isActive ? 1 : 0 }}
          onClick={(e) => {
            if (isActive) {
              e.stopPropagation();
              onRemove?.(preview.table);
            }
          }}
        />
      ) : (
        <Loader2 className="text-content/40 size-4 animate-spin" />
      )}
    </Button>
  );
};

export const convertTablePath = (tablePath: string) => {
  const [database, schema, table] = tablePath.split(".");
  return { database, schema, table };
};

const DownloadButton = ({ queryId, run }: { queryId: string; run?: QueryRunResult }) => {
  const isDisabled = !run || !run.resultsRetrieved;
  const { currentUser } = useCurrentUser();
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    if (isDisabled) return;

    if (!currentUser) {
      setIsOpen(true);
      return;
    }

    exportToCsv(run?.columns, run?.csvData, `query-results-${queryId}`);
  };

  return (
    <>
      <Tooltip content="Download CSV" side="top">
        <Button variant="secondary" iconOnly size="sm" onClick={handleClick} disabled={isDisabled}>
          <Download className="text-muted-foreground size-4" />
        </Button>
      </Tooltip>
      <MarketingAuthModal
        defaultOpen={false}
        onOpenChange={setIsOpen}
        open={isOpen}
        origin="nav-studio"
        persona="analyst"
        redirectBackOnClose={false}
      />
    </>
  );
};

const TransposeButton = ({ queryId, run }: { queryId: string; run?: QueryRunResult }) => {
  const { activeResults } = useQueryResultsStore();
  const tableId = createTableId(queryId, activeResults, run);
  const { state, setState } = useTableState(tableId);
  return (
    <Tooltip content="Transpose" side="bottom">
      <Button
        variant="secondary"
        iconOnly
        size="sm"
        onClick={() => {
          setState({ transposed: !state.transposed });
          tracking("editor_rotate_table", "Dashboard Beta");
        }}
      >
        <RotateCcwSquare className="text-muted-foreground size-4" />
      </Button>
    </Tooltip>
  );
};

const createTableId = (
  queryId: string,
  activeResults: QueryResultsContainerStore["activeResults"],
  run?: QueryRunResult,
): TableId => {
  const tableId =
    activeResults === "query"
      ? `query-${queryId}-${run?.queryRunId}`
      : activeResults === "selectAndRun"
        ? `selectAndRun-${queryId}-${run?.queryRunId}`
        : `preview-${activeResults}`;
  return tableId as TableId;
};
