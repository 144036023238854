import { QueryRunResult } from "@fscrypto/domain/query-run";
import { createStore, useStore } from "@fscrypto/state-management";
import { QueryClient } from "../data/query-client";
import { Client } from "@fscrypto/http";
import { QueryPoller } from "../data/query-poller";
import { useQueryResultsStore } from "../ui/query-results";
import { queryRun } from "@fscrypto/domain";

const ephemeralRunStore = createStore<Record<string, { result: QueryRunResult; statement: string } | undefined>>({});
const queryClient = new QueryClient(new Client());

const pollers = new Map<string, QueryPoller>();

export const useSelectAndRun = (queryId: string) => {
  const results = useStore(ephemeralRunStore, (s) => s[queryId]);
  const { setActiveResults } = useQueryResultsStore();
  const executeSelectAndRun = async (statement: string) => {
    setActiveResults("selectAndRun");
    const emptyRun = queryRun.createEmptyQueryRunResult(queryId);
    ephemeralRunStore.set({ [queryId]: { result: emptyRun, statement } });
    const result = await queryClient.execute({
      queryId,
      statement,
      executionType: "SELECT_AND_RUN",
    });
    if (!result.queryRunId) return;

    ephemeralRunStore.set({ [queryId]: { result, statement } });
    const poller = new QueryPoller(result.queryRunId, queryClient);
    poller.results$.subscribe((result) => {
      ephemeralRunStore.set({ [queryId]: { result, statement } });
    });
    pollers.set(queryId, poller);
    poller.poll();
  };

  const removeSelectAndRun = () => {
    ephemeralRunStore.set({ ...ephemeralRunStore.get(), [queryId]: undefined });
  };

  const cancelSelectAndRun = async () => {
    const poller = pollers.get(queryId);
    if (!poller) return;
    poller.cancel();
    pollers.delete(queryId);
  };
  const currentResult = ephemeralRunStore.get()[queryId]?.result;
  const isSelectAndRunRunning = currentResult
    ? queryRun.isRunning(currentResult.status) ||
      (queryRun.isFinished(currentResult.status) && !currentResult.resultsRetrieved)
    : false;

  return { results, executeSelectAndRun, removeSelectAndRun, cancelSelectAndRun, isSelectAndRunRunning };
};
